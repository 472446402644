import Client from "./prismicConfig";
import Prismic from "@prismicio/client";

export const fetchData = (setCtx, path, value) => {
  Client.query(Prismic.Predicates.at(path, value), { pageSize: 100 })
    .then((res) => {
      setCtx({ data: res.results, loading: false, error: false });
    })
    .catch((err) => {
      console.error("Error: ", err.message);
      setCtx({ loading: false, error: true });
    });
};
