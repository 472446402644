import React from "react";
import "./Loading.css";

export default function Loading() {
  return (
    <div className="loading-container">
      <div className="animation" />
      {/* <div>one moment...</div> */}
    </div>
  );
}
