import React, { useState, useEffect } from "react";
import Loading from "../common/Loading";
import Error from "../common/Error";
import "./CV.css";
import Client from "../utils/prismicConfig";
import Prismic from "@prismicio/client";
import CvSectionD from "./components/cvSectionD";
import CvSectionS from "./components/cvSectionS";
import Layout from "../common/Layout";

export default function CV() {
  const [ctx, setCtx] = useState({ data: null, loading: true, error: null });

  const fetchCV = async (setCtx) => {
    const results = [];
    let page = 1;
    let moreInfo = true;
    while (moreInfo) {
      const res = await Client.query(
        Prismic.Predicates.at("document.type", "cv-item-test"),
        {
          orderings: "[my.cv-item-test.start_date desc]",
          pageSize: 100,
          page,
        }
      );
      const response = res.results;
      response.forEach((r) => {
        results.push(r);
      });
      if (!res.next_page) {
        moreInfo = false;
      }
      page++;
    }
    setCtx({ data: results });
  };

  useEffect(() => {
    fetchCV(setCtx);
  }, []);

  if (ctx.loading) return <Loading />;
  if (!ctx.data || ctx.error) return <Error />;

  const document = ctx.data;
  console.log(ctx);

  // Categories

  const education = document.filter(
    (item) => item.data.category === "education"
  );
  const awards = document.filter((item) => item.data.category === "awards");
  const research = document.filter((item) => item.data.category === "research");
  const peerReviewed = document.filter(
    (item) => item.data.category === "pub_peer_reviewed"
  );
  const academicWriting = document.filter(
    (item) => item.data.category === "pub_other_academic_writing"
  );
  const dataset = document.filter(
    (item) => item.data.category === "pub_data_set"
  );
  const academic = document.filter(
    (item) => item.data.category === "pres_academic_conference"
  );
  const professional = document.filter(
    (item) => item.data.category === "pres_professional_conference"
  );
  const invited = document.filter(
    (item) => item.data.category === "pres_invited_talk"
  );
  const campus = document.filter(
    (item) => item.data.category === "pres_campus_talk"
  );
  const publicTalk = document.filter(
    (item) => item.data.category === "pres_public_talk"
  );
  const teaching = document.filter((item) => item.data.category === "teaching");
  const mentorship = document.filter(
    (item) => item.data.category === "mentorship"
  );
  const professionalDev = document.filter(
    (item) => item.data.category === "professional_dev"
  );
  const media = document.filter(
    (item) => item.data.category === "select_media"
  );
  const procomm = document.filter(
    (item) => item.data.category === "select_procomm_exp"
  );
  const affiliations = document.filter(
    (item) => item.data.category === "select_affiliations"
  );

  return (
    <Layout>
      <div className="cv-container">
        <CvSectionD title="education" list={education} />
        <CvSectionD title="awards" list={awards} />
        <CvSectionD title="research" list={research} />
        {/* PUBLICATIONS */}
        <CvSectionS
          title="Publications"
          subtitle="Peer Reviewed Publications"
          list={peerReviewed}
        />
        <CvSectionS subtitle="Academic Writing" list={academicWriting} />
        <CvSectionS subtitle="Datasets" list={dataset} />
        {/* PRESENTATIONS */}
        <CvSectionS
          title="Presentations"
          subtitle="Academic Conferences"
          list={academic}
        />
        <CvSectionS subtitle="Professional Conferences" list={professional} />
        <CvSectionS subtitle="Invited Talks" list={invited} />
        <CvSectionS subtitle="Campus Talks" list={campus} />
        <CvSectionS subtitle="Public Talks" list={publicTalk} />
        {/* END Presentations */}
        <CvSectionD title="teaching" list={teaching} />
        <CvSectionD title="mentorship" list={mentorship} />
        <CvSectionD title="Professional Development" list={professionalDev} />
        <CvSectionD title="Select Media Coverage" list={media} />
        <CvSectionD title="Select Professional Communication" list={procomm} />
        <CvSectionD title="Select Affiliations" list={affiliations} />
        <div className="download">
          <a href="/documents/CV2023.pdf" target="_blank" rel="noreferrer">
            Download PDF
          </a>
        </div>
      </div>
    </Layout>
  );
}
