import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Navigation from "./Navigation";
import "./Header.css";
import clsx from "clsx";
import { useIsMobile, useIsTablet } from "../utils/utils";

export default function Header() {
  const history = useHistory();
  const [hidden, setHidden] = useState(false);
  const [isHome, setIsHome] = useState(null);
  const location = useLocation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isMobileOrTablet = isMobile || isTablet;

  useEffect(() => {
    const checkPathname = () => {
      if (location.pathname === "/") {
        setIsHome(true);
      } else {
        setIsHome(false);
      }
    };
    checkPathname();
    if (isHome === true && !isMobileOrTablet) {
      setHidden(true);
    }
  }, [location, isMobileOrTablet, isHome]);

  return (
    <div className="header-root">
      <div
        onClick={() => history.push("/")}
        className={clsx("header-title-container", hidden === true && "hide")}
      >
        <h1 className={clsx("header-title", hidden === true && "hide")}>
          alice fleerackers
        </h1>
      </div>
      <div className="header-action-container">
        <Navigation />
      </div>
    </div>
  );
}
