import React from "react";
import "./Button.css";
import clsx from "clsx";

export default function Button({ onClick, url, className, children, newTab }) {
  return (
    <button className={clsx("btn", className)} onClick={onClick}>
      <a
        className="btn-content"
        href={url}
        target={newTab && "_blank"}
        rel="noopener noreferrer"
        id={children}
      >
        {children}
      </a>
    </button>
  );
}
