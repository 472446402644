import { useEffect, useState } from "react";

const BREAKPOINT_SM = 600;
const BREAKPOINT_MD = 960;

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINT_SM);

  useEffect(() => {
    let mounted = true;
    const handler = () => {
      if (!mounted) return;
      setIsMobile(window.innerWidth < BREAKPOINT_SM);
    };
    window.addEventListener("resize", handler);
    return () => {
      mounted = false;
      window.removeEventListener("resize", handler);
    };
  }, []);
  return isMobile;
}
export function useIsTablet() {
  const [isTablet, setIsTablet] = useState(window.innerWidth < BREAKPOINT_MD);

  useEffect(() => {
    let mounted = true;
    const handler = () => {
      if (!mounted) return;
      setIsTablet(window.innerWidth < BREAKPOINT_MD);
    };
    window.addEventListener("resize", handler);
    return () => {
      mounted = false;
      window.removeEventListener("resize", handler);
    };
  }, []);
  return isTablet;
}
