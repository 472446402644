import { RichText } from "prismic-reactjs";
import React from "react";
import "../CV.css";

export default function CvSectionS({ title, subtitle, list }) {
  return (
    <div className="cv-section-container">
      {list.map((e, i) => (
        <div key={e.id} className="cv-s-section">
          <div className="cv-section-title">{i === 0 && title && title}</div>
          <div className="cv-s-item">
            <div className="cv-section-subtitle">
              {i === 0 ? subtitle : null}
            </div>
            <div className="cv-details">
              <div className="cv-s-title">{RichText.render(e.data.title)}</div>
              <div className="cv-subtitle">
                {RichText.render(e.data.subtitle)}
              </div>
            </div>
            {/* <div className="cv-date">
              {RichText.render(e.data.display_date)}
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
}
