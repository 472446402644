import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Portfolio from "./Pages/Portfolio";
import CV from "./Pages/CV";

function App() {
  return (
    <Switch>
      <Route path="/about" component={About} />
      <Route path="/portfolio" component={Portfolio} />
      <Route path="/cv" component={CV} />
      <Route path="/portfolio/media-and-society" component={Portfolio} />
      <Route path="/portfolio/health-and-science" component={Portfolio} />
      <Route path="/portfolio/art-and-design" component={Portfolio} />
      <Route path="/" component={Home} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

export default App;
