import Prismic from "@prismicio/client";

const apiEndpoint = "https://alice-fleerackers.cdn.prismic.io/api/v2";
const accessToken =
  "MC5YS3J3akJJQUFHRUlSanBH.77-9UkVpHu-_ve-_ve-_ve-_ve-_vXRucGrvv71Acx4o77-977-977-977-9O--_ve-_vRXvv73vv70dU0U";

const Client = Prismic.client(apiEndpoint, { accessToken });

export default Client;

export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === "portfolio-item]") {
    return `/portfolio/not${doc.uid}`;
  }

  // URL for a specific page
  // TODO update to proper doc.type.name?
  if (doc.type === "page") {
    return `/${doc.type}`;
  }

  // Backup for all other types
  return "/";
};
