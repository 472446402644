import React, { useState, useEffect } from "react";
import { fetchData } from "../utils/prismicUtils";
import Loading from "../common/Loading";
import Error from "../common/Error";
import "./Home.css";
import { RichText } from "prismic-reactjs";
import { useIsMobile, useIsTablet } from "../utils/utils";
import Layout from "../common/Layout";

export default function Home() {
  const [ctx, setCtx] = useState({ data: null, loading: true, error: null });
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isMobileOrTablet = isMobile || isTablet;

  useEffect(() => {
    fetchData(setCtx, "document.type", "home_page");
  }, []);

  if (ctx.loading) return <Loading />;
  if (!ctx.data || ctx.error) return <Error />;

  const document = ctx.data[0].data;

  return (
    <Layout>
      <div className="container">
        {isMobileOrTablet === false && (
          <div className="left">
            <h1>
              alice <br /> fleerackers
            </h1>
          </div>
        )}
        <div className="right">
          <div className="right-main">{RichText.render(document.intro)}</div>
        </div>
      </div>
    </Layout>
  );
}
