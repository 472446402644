import React, { useState, useEffect } from "react";
import { fetchData } from "../utils/prismicUtils";
import Loading from "../common/Loading";
import Error from "../common/Error";
import "./PortfolioList.css";
import { RichText } from "prismic-reactjs";
// import Button from "../common/Button";
import clsx from "clsx";
import PortfolioListMobile from "./PortfolioListMobile";
import { useIsMobile, useIsTablet } from "../utils/utils";

export default function PortfolioList({ list }) {
  const [ctx, setCtx] = useState({ data: null, loading: true, error: null });
  const [selected, setSelected] = useState(0);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  useEffect(() => {
    fetchData(setCtx, "my.portfolio_item.category", list);
    // setFilter("feature");
  }, [list]);

  if (ctx.loading) return <Loading />;
  if (!ctx.data || ctx.error) return <Error />;

  const document = ctx.data;
  const d = document[selected];

  return (
    <>
      {isMobile || isTablet ? (
        <PortfolioListMobile list={list} />
      ) : (
        <div className="container">
          <div className="p-list">
            {/* <div className="filter-list-button">
              {list.replaceAll("-", " ").replace("and", "+")}
            </div> */}

            {document.map((d, i) => (
              <div
                key={i}
                className="portfolio-list-title"
                id={clsx(selected === i && "selected")}
                onClick={() => setSelected(i)}
              >
                {d.data.title[0].text}
              </div>
            ))}
          </div>
          <div className="p-item">
            <div className="p-item-container">
              <div
                className="portfolio-photo"
                style={{
                  backgroundImage: `url(${d.data.cover_image.url})`,
                }}
              />
              <h2>{d.data.title[0].text}</h2>
              <div className="p-item-pub">
                <i>{d.data.publication[0].text}</i>
              </div>
              <div className="p-item-date">
                {RichText.render(d.data.issue_and_date)}
              </div>
              <div className="p-item-short">
                {RichText.render(d.data.short)}
              </div>
              <div className="excerpt">Excerpt</div>
              <div className="p-item-excerpt">
                {RichText.render(d.data.excerpt)}
              </div>
              <div>
                <a
                  href={d.data.link_to_article.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Read Full Article
                </a>

                <div className="spacer"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
