import React, { useState, useEffect } from "react";
import { fetchData } from "../utils/prismicUtils";
import Loading from "../common/Loading";
import Error from "../common/Error";
import "./About.css";
import { RichText } from "prismic-reactjs";
import Layout from "../common/Layout";

export default function About() {
  const [ctx, setCtx] = useState({ data: null, loading: true, error: null });

  useEffect(() => {
    fetchData(setCtx, "document.type", "about_page");
  }, []);

  if (ctx.loading) return <Loading />;
  if (!ctx.data || ctx.error) return <Error />;

  const document = ctx.data[0].data;

  return (
    <Layout>
      <div className="container">
        <div className="left">
          <div
            className="photo"
            style={{ backgroundImage: `url(${document.photo.url})` }}
          />
          <div className="caption">
            Photo by Josie Baik |{" "}
            <a
              href="https://baiktobasics.com/"
              target="_blank"
              rel="noreferrer"
            >
              Baik to Basics
            </a>
          </div>
        </div>
        <div className="right">
          <div className="bio">{RichText.render(document.bio)}</div>
        </div>
      </div>
    </Layout>
  );
}
