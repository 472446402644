import React, { useState, useEffect } from "react";
import { fetchData } from "../utils/prismicUtils";
import Loading from "../common/Loading";
import Error from "../common/Error";
import "./PortfolioListMobile.css";
import { RichText } from "prismic-reactjs";
import Button from "../common/Button";
import clsx from "clsx";
import { useIsMobile, useIsTablet } from "../utils/utils";

export default function PortfolioListMobile({ list }) {
  const [ctx, setCtx] = useState({ data: null, loading: true, error: null });
  const [selected, setSelected] = useState(null);
  const [overlay, showOverlay] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const setItem = (i) => {
    showOverlay(true);
    setSelected(i);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const removeItem = () => {
    showOverlay(false);
    setSelected(null);
  };

  useEffect(() => {
    fetchData(setCtx, "my.portfolio_item.category", list);
    // showOverlay === true && window.scrollTo(0, 0); // TODO update this to work
  }, [list, showOverlay]);

  if (ctx.loading) return <Loading />;
  if (!ctx.data || ctx.error) return <Error />;

  const document = ctx.data;
  const d = document[selected];
  return (
    <>
      <div className="container">
        <div className="title-list">
          {document.map((d, i) => (
            <div
              key={i}
              className="mobile-p-preview"
              onClick={() => setItem(i)}
            >
              <div
                key={d.id}
                className="mobile-p-title"
                id={clsx(selected === i && "selected")}
              >
                {d.data.title[0].text}
              </div>
              <div className="mobile-p-short"> {d.data.short[0].text}</div>
              {isTablet && !isMobile && (
                <div className="mobile-p-excerpt">
                  {" "}
                  {d.data.excerpt[0].text}
                </div>
              )}
              {/* <a
                href={d.data.link_to_article.url}
                target="_blank"
                rel="noreferrer"
              >
                Read article
              </a> */}
            </div>
          ))}
        </div>
        {overlay && selected !== null && (
          <div className="overlay">
            <Button className="o-close-btn" onClick={() => removeItem()}>
              Close
            </Button>
            <div className="o-item">
              <div className="o-item-container">
                <div
                  className="o-photo"
                  style={{
                    backgroundImage: `url(${d.data.cover_image.url})`,
                  }}
                />
                <h2>{d.data.title[0].text}</h2>
                <div className="text-small">
                  <i>{RichText.render(d.data.publication)}</i>
                </div>
                <div className="text-small">
                  {RichText.render(d.data.issue_and_date)}
                </div>
                <br />
                <div className="o-short">{RichText.render(d.data.short)}</div>
                <div className="excerpt">Excerpt</div>
                <div className="o-excerpt">
                  {RichText.render(d.data.excerpt)}
                </div>
                <div>
                  <a
                    href={d.data.link_to_article.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read Full Article
                  </a>

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
