import React, { useState, useEffect } from "react";
import { fetchData } from "../utils/prismicUtils";
import Loading from "../common/Loading";
import Error from "../common/Error";
import Feature from "./Feature";
import PortfolioList from "./PortfolioList";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../common/Layout";

export default function Portfolio() {
  const [ctx, setCtx] = useState({ data: null, loading: true, error: null });
  const [filter, setFilter] = useState(null);
  const location = useLocation();
  const documentFilter = location.pathname.slice(11);

  useEffect(() => {
    fetchData(setCtx, "document.type", "portfolio_item");
    setFilter("feature");
    if (location.pathname !== "/portfolio") {
      setFilter(documentFilter);
    }
  }, [location, documentFilter]);

  if (ctx.loading) return <Loading />;
  if (!ctx.data || !ctx.data.length || ctx.error) return <Error />;

  return (
    <Layout>
      {filter === "feature" ? (
        <Feature />
      ) : (
        <PortfolioList list={documentFilter} />
      )}
    </Layout>
  );
}
