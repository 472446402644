import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import "./Navigation.css";

const NAVLINKS = {
  about: {
    name: "About",
    path: "/about",
  },
  portfolio: {
    name: "Portfolio",
    path: "/portfolio",
  },
  cv: {
    name: "CV",
    path: "/cv",
  },
};

const PAGES = ["about", "portfolio", "cv"];

export default function Navigation() {
  const location = useLocation();

  const navmap = PAGES.map((page) => NAVLINKS[page]);

  const navlinks = useMemo(() => {
    const links = [];
    for (let i = 0; i < 3; i++) {
      const entry = navmap[i];
      links.push(
        <a className="navlink" key={i} href={entry.path}>
          {entry.name}
        </a>
      );
    }
    return links;
  }, [navmap]);

  const portfolioNav = (
    <div className="portfolio-filter">
      <a
        className={clsx(
          "filter-button",
          location.pathname === "/portfolio/health-and-science" && "active"
        )}
        href="/portfolio/health-and-science"
      >
        health + science
      </a>
      <a
        className={clsx(
          "filter-button",
          location.pathname === "/portfolio/art-and-design" && "active"
        )}
        href="/portfolio/art-and-design"
      >
        art + design
      </a>
      <a
        className={clsx(
          "filter-button",
          location.pathname === "/portfolio/media-and-society" && "active"
        )}
        href="/portfolio/media-and-society"
      >
        media + society
      </a>
    </div>
  );

  return (
    <div className="nav-links-container">
      {navlinks}
      {location.pathname.includes("/portfolio") && portfolioNav}
    </div>
  );
}
