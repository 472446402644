import React from "react";
import "./Footer.css";
export default function Footer() {
  return (
    <div className="footer-root">
      <div className="social">
        <a
          href="https://twitter.com/fleerackersA"
          target="blank"
          rel="noreferrer"
          aria-label="twitter-icon"
        >
          <div className="social-icon" id="twitter">
            <span style={{ display: "none" }}>twitter</span>
          </div>
        </a>
        <a
          href="https://medium.com/@alicefleerackers"
          target="blank"
          rel="noreferrer"
          aria-label="medium-icon"
        >
          <div className="social-icon" id="medium">
            <span style={{ display: "none" }}>medium</span>
          </div>
        </a>
        <a
          href="https://www.linkedin.com/in/alice-fleerackers/"
          target="blank"
          rel="noreferrer"
          aria-label="linked-in-icon"
        >
          <div className="social-icon" id="linkedin">
            <span style={{ display: "none" }}>linkedin</span>
          </div>
        </a>
      </div>
      <div className="email">
        <a className="cta" href="mailto:hello@alicefleerackers.com">
          say hello@alicefleerackers.com
        </a>
      </div>
    </div>
  );
}
