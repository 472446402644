import React from "react";
import "./Error.css";

export default function Error() {
  return (
    <div className="error-container">
      <div className="error-img" />
      <div className="error-msg">
        Oops, something went wrong here. <br />
        Try refreshing or going back.
      </div>
    </div>
  );
}
