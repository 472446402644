import React, { useState, useEffect } from "react";
import { fetchData } from "../utils/prismicUtils";
import Loading from "../common/Loading";
import Error from "../common/Error";
import "./Feature.css";
import { RichText } from "prismic-reactjs";
import Button from "../common/Button";

export default function Feature() {
  const [ctx, setCtx] = useState({ data: null, loading: true, error: null });
  useEffect(() => {
    fetchData(setCtx, "my.portfolio_item.is_feature", true);
  }, []);

  if (ctx.loading) return <Loading />;
  if (!ctx.data || ctx.error) return <Error />;

  const document = ctx.data[0].data;

  return (
    <div className="container">
      <div className="left">
        <Button className="filter-list-button">Feature</Button>
        <h1 className="feature-title">{RichText.render(document.title)}</h1>
        {/* {!isMobileOrTablet && ( */}
        <a
          className="feature-link"
          target="_blank"
          rel="noreferrer"
          href={document.link_to_article.url}
        >
          Read Now
        </a>
        {/* )} */}
      </div>
      <div className="right">
        <div
          className="feature-photo"
          style={{ backgroundImage: `url(${document.cover_image.url})` }}
        />
        {/* {isMobileOrTablet && (
          <a
            className="feature-link"
            target="_blank"
            rel="noreferrer"
            href={document.link_to_article.url}
          >
            Read Now
          </a>
        )} */}
      </div>
    </div>
  );
}
